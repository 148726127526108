@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
/* Style the accordion section */
.accordion__section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  margin-left: 40px;
  background-color: #eee;
  color: #000000;
  cursor: pointer;
  padding: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-top: 2px solid #ffffff;
  width: 100%;
  height: 70px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #ccc;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #efefef;
  overflow: auto;
  margin-left: 40px;
  width: 563px;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding: 18px;
}

@media only screen and (max-width: 375px) {
  .accordion__content {
    width: 100vw;
  }
  .accordion__text {
    font-size: 16px;
    text-align: left;
  }
}

/*--------------------------------

houpa Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'houpa';
  src: url(/static/media/houpa.f18ec10e.eot);
  src: url(/static/media/houpa.f18ec10e.eot) format('embedded-opentype'),
    url(/static/media/houpa.5ab1fb1a.woff2) format('woff2'),
    url(/static/media/houpa.4472d4c1.woff) format('woff'),
    url(/static/media/houpa.ad089b25.ttf) format('truetype'),
    url(/static/media/houpa.d6e1e0f9.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'houpa';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-sm {
  font-size: 0.8em;
}
.icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.icon-16 {
  font-size: 16px;
}
.icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.icon-ul > li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  line-height: 1.4;
}
.icon-ul > li > .icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.icon-app-store::before {
  content: '\ea01';
}

.icon-arrow-down::before {
  content: '\ea02';
}

.icon-arrow-up::before {
  content: '\ea03';
}

.icon-box-ok::before {
  content: '\ea04';
}

.icon-cart-color::before {
  content: '\ea05';
}

.icon-check-circle::before {
  content: '\ea06';
}

.icon-check::before {
  content: '\ea07';
}

.icon-dispositivos-coracao::before {
  content: '\ea08';
}

.icon-dispositivos::before {
  content: '\ea09';
}

.icon-document-list::before {
  content: '\ea0a';
}

.icon-document::before {
  content: '\ea0b';
}

.icon-etiqueta::before {
  content: '\ea0c';
}

.icon-facebook::before {
  content: '\ea0d';
}

.icon-google-play::before {
  content: '\ea0e';
}

.icon-hand-click::before {
  content: '\ea0f';
}

.icon-instagram::before {
  content: '\ea10';
}

.icon-linkedin::before {
  content: '\ea11';
}

.icon-logo-houpa-business::before {
  content: '\ea12';
}

.icon-monitor-pencil::before {
  content: '\ea13';
}

.icon-relatorios::before {
  content: '\ea14';
}

.icon-roupas::before {
  content: '\ea15';
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'),
    url(/static/media/Roboto-Thin.c38fab23.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'),
    url(/static/media/Roboto-Light.0cea3982.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url(/static/media/Roboto-Regular.03523cf5.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url(/static/media/Roboto-Medium.13a29228.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url(/static/media/Roboto-Bold.4f39c579.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'),
    url(/static/media/Roboto-Black.1224b64c.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins-Thin'),
    url(/static/media/Poppins-Thin.a61eaf4c.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'),
    url(/static/media/Poppins-Light.f41b63c0.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url(/static/media/Poppins-Regular.8081832f.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url(/static/media/Poppins-Medium.9e1bb626.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-Bold'),
    url(/static/media/Poppins-SemiBold.cce5625b.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'),
    url(/static/media/Poppins-Bold.404e299b.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Black';
  src: local('Poppins-Black'),
    url(/static/media/Poppins-Black.eacee5bb.ttf) format('truetype');
  font-weight: normal;
}

