/*--------------------------------

houpa Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'houpa';
  src: url('../fonts/Icons/houpa.eot');
  src: url('../fonts/Icons/houpa.eot') format('embedded-opentype'),
    url('../fonts/Icons/houpa.woff2') format('woff2'),
    url('../fonts/Icons/houpa.woff') format('woff'),
    url('../fonts/Icons/houpa.ttf') format('truetype'),
    url('../fonts/Icons/houpa.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'houpa';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-sm {
  font-size: 0.8em;
}
.icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.icon-16 {
  font-size: 16px;
}
.icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.icon-ul > li > .icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  -moz-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.icon-app-store::before {
  content: '\ea01';
}

.icon-arrow-down::before {
  content: '\ea02';
}

.icon-arrow-up::before {
  content: '\ea03';
}

.icon-box-ok::before {
  content: '\ea04';
}

.icon-cart-color::before {
  content: '\ea05';
}

.icon-check-circle::before {
  content: '\ea06';
}

.icon-check::before {
  content: '\ea07';
}

.icon-dispositivos-coracao::before {
  content: '\ea08';
}

.icon-dispositivos::before {
  content: '\ea09';
}

.icon-document-list::before {
  content: '\ea0a';
}

.icon-document::before {
  content: '\ea0b';
}

.icon-etiqueta::before {
  content: '\ea0c';
}

.icon-facebook::before {
  content: '\ea0d';
}

.icon-google-play::before {
  content: '\ea0e';
}

.icon-hand-click::before {
  content: '\ea0f';
}

.icon-instagram::before {
  content: '\ea10';
}

.icon-linkedin::before {
  content: '\ea11';
}

.icon-logo-houpa-business::before {
  content: '\ea12';
}

.icon-monitor-pencil::before {
  content: '\ea13';
}

.icon-relatorios::before {
  content: '\ea14';
}

.icon-roupas::before {
  content: '\ea15';
}
